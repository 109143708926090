/* Add the following styles to your Index.css file */
body {
  
}


/* Spacing between menu items */
.navbar-nav > li {
  margin-right: 10px;
}

/* Style for the Sign In button */
.navbar-nav > li button {
  margin-right: 10px;
  padding: 8px 15px;
  background-color: transparent;
  border: 2px solid #ffffff; /* White border, you can customize */
  border-radius: 20px; /* Rounded border, adjust the value as needed */
  color: white;
  cursor: pointer;
}

/* Hover effect for the Sign In button */
.navbar-nav > li button:hover {
  background-color: #ffffff; /* White background on hover, you can customize */
  color: #343a40; /* Dark color for text on hover, you can customize */
}

/* * Style for the Get Started button  */
.navbar-nav > li:last-child button {
  background-color: #007bff; /* Blue background color, you can customize */
  color: white;
  border: none;
}

/* Hover effect for the Get Started button */
.navbar-nav > li:last-child button:hover {
  background-color: #0056b3; /* Darker blue color on hover, you can customize */
}

/* Style for the logo */
.navbar-brand {
  font-size: 1.5rem; /* Adjust the font size as needed */
}

/* Customize navbar background color */
.navbar {
  background-color: #343a40; /* Dark grey color, you can customize */
}

/* Customize navbar link color */
.navbar-nav > li > a {
  color: #ffffff; /* White color for links, you can customize */
}

/* Hover effect for links */
.navbar-nav > li > a:hover {
  color: #e0e0e0; /* Lighter grey color on hover, you can customize */
}

/* Add the following styles to your Index.css file */

/* Style for the Logo */
.navbar-brand img {
  margin-right: 15px; /* Add margin to the right of the logo */
}

/* Spacing between menu items */
.navbar-nav > li {
  margin-right: 15px; /* Add margin to the right of each menu item */
}

/* Spacing between the Sign In and Get Started buttons */
.navbar-nav > li:last-child {
  margin-right: 15px; /* Add margin to the right of the last menu item (Sign In button) */
}

/* Adjust spacing for the Get Started button */
.navbar-nav > li:last-child button {
  margin-left: 15px; /* Add margin to the left of the Get Started button */
}

/* Add the following styles to your Index.css file */

/* Style for the Logo */
.navbar-brand img {
  margin-right: 15px; /* Add margin to the right of the logo */
}

/* Spacing between menu items */
.navbar-nav > li {
  margin-right: 15px; /* Add margin to the right of each menu item */
}

/* Spacing between the Sign In and Get Started buttons */
.navbar-nav > li:last-child {
  margin-right: 15px; /* Add margin to the right of the last menu item (Sign In button) */
}

/* Adjust spacing for the Get Started button */
.navbar-nav > li:last-child button {
  margin-left: 15px; /* Add margin to the left of the Get Started button */
}

/* Increase font size for menu items */
.navbar-nav > li > a {
  font-size: 18px; /* Adjust the font size as needed */
}

/* Increase font size for Sign In and Get Started buttons */
.navbar-nav > li button {
  font-size: 16px; /* Adjust the font size as needed */
}

/* Increase font size for the Logo */
.navbar-brand {
  font-size: 2rem; /* Adjust the font size as needed */
}

/* Add the following styles to your Index.css file */

/* Style for the Get Started button */
.navbar-nav > li:last-child button {
  border-radius: 100px; /* Set border-radius to create a circular shape */
  background: linear-gradient(
    266deg,
    #246cf9 -0.27%,
    #1e68f6 -0.26%,
    #0047d0 98.59%
  );
  /* Set a linear gradient background with the specified colors */
}

.button-blue-rounded {
  border-radius: 100px; /* Set border-radius to create a circular shape */
  background: linear-gradient(
    266deg,
    #246cf9 -0.27%,
    #1e68f6 -0.26%,
    #0047d0 98.59%
  );
}

.mx-10 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.custom-nav-link-style {
  background-color: #343a40;
  border: none; 
}

.border-none {
  border: 2px solid red;
}

.w-fit {
  width: fit-content;
}

/* ------------------------------------------------------------------------------------------- */


/* Media query for screens with a maximum width of 767 pixels (typical for mobile screens) */
@media (max-width: 767px) {
  .mx-10 {
    margin-left: 1rem; /* Adjusted margin for smaller screens */
    margin-right: 1rem; /* Adjusted margin for smaller screens */
  }
}
/* ------------------------------------------- */

/* Card.css */
.cardL {
  /* width: 300px; */
  border-radius: 8px;
  margin: 20px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: #272933;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.card-icon-color1 {
  color: #ffa500;
}

.card-icon-color2 {
  color: #4deeea;
}

.card-icon-color3 {
  color: #f000ff;
}

.card-icon {
  display: flex;
  justify-content: center;
  background-color: #343a40;
  padding: 25px;
  width: fit-content;
  border-radius: 100%;
}

.usage-icon {
  display: flex;
  justify-content: center;
  background-color: #343a40;
  padding: 20px;
  width: fit-content;
  border-radius: 100%;
}

.choose-icon {
  display: flex;
  justify-content: center;
  background-color: #343a40;
  padding: 2.25rem;
  width: fit-content;
  height: fit-content;
  border-radius: 15%;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.card-text {
  font-size: 1rem;
  color: #a3a3a3;
  text-align: center;
}

.flex-alignment {
  display: flex;
}

.space-y-1 {
  margin-top: 2.25rem;
}

.margin-right {
  margin-right: 1.2rem;
}

.padding-bottom {
  padding-bottom: 2.50rem;
}

.app-btn {
  width: fit-content;
}

.text-left {
  text-align: left;
}

.input-group {
  position: relative;
}

.input-group-append {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 1rem 0.75rem; /* Adjust top and bottom padding for space */
}

.form-control {
  padding-right: 2.75rem; /* Adjust the right padding for space */
}

.newsletter-bg-img {
  background-image: url("../public/vector.png");
  background-size: contain;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}


/* Mobile Screens */

/* Extra Small Devices (phones) */
@media only screen and (max-width: 600px) {
  /* CSS rules for extra small devices go here */
  .line-bg-img {
    background-image: none;
  }

  .mobile-line-bg-img {
    background-image: url("../public/line.png");
    background-size: contain;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
  }

  .navbar-bg-mobile {
    background-color: #272933;
  }

  .hero-image {
    display: none;
  }

  .w-mobile {
    width: 100%;
  }

  .w-lg-75 {
    width: 100%;
    text-align: center;
  }

  .half-circle-bg {
    background-image: url("../public/half-circle.png");
    background-size: contain;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
  }

  .sm-text {
    font-size: 1.2rem;
  }

  .choose-icon {
    display: flex;
    justify-content: center;
    background-color: #343a40;
    padding: 1.25rem;
    width: fit-content;
    height: fit-content;
    border-radius: 15%;
  }

  .w-sm-100 {
    width: 100%;
  }
}

/* Small Devices (tablets) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  /* CSS rules for small devices go here */
  .hero-image {
    display: none;
  }

  .w-sm-100 {
    width: 100%;
  }


}

/* Medium Devices (desktops) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
  /* CSS rules for medium devices go here */
  .hero-image {
    display: none;
  }
}

/* Large Devices (large desktops) */
@media only screen and (min-width: 993px) {
  .w-lg-0 {
    width: auto;
  }

  .w-lg-75 {
    width: 75%;
  }

  .lg-text {
    font-size: 2.00rem;
  }

  .w-lg-50 {
    width: 50%;
  }

}
