.p2p-main-header{
    color:#03A9F4;
}
.top--p2p-header{
   
    text-align: center;
    margin-top: 80px;
    background: rgb(20, 26, 40);
}
.p2p-table-data td{
    padding: 1.2rem !important;
}
.top-tab-background{
    background-color: #fff;
}
.p2p-table-heading th{
    padding: 1rem !important;
    /* background: rgb(218, 218, 218); */
    border-top: none !important;
    border-radius: 1px;
}
.p2p-hader-para{
    color:#B7BDC6;
}
.p2p-works-container{
    background-color: #fafafa;
    border-radius: 10px;
    padding: 20px;
}
.card-main-container{
    border:1px solid rgb(219, 219, 219);
    border-radius: 15px;
    padding: 10px;
    margin: 5px;
}
.p2p-advantage-section{
    margin-top: 30px;
}
.p2p-account-detail-para{
    padding-left: 13px;
}
.p2p-card-body{
    padding-left: 20px;
}
.detail-box{
    border-radius: 10px;
}
.card-container-transaction{
    background-color: #fff;
    background-color: #2e3748;
}