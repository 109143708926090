
/* .nav-item.buy.active {
  border-radius: 0 !important;
  border-bottom: 0 !important;
  box-sizing: border-box;
} */
/* nav .nav-item.buy.active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: red !important;
}
nav .nav-item.active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: green !important;
} */

/* nav .nav-item.active {
  border-radius: 0 !important;
  border-bottom: 0 !important;
  box-sizing: border-box;
}
.nav-item {
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  border-radius: 0 !important;
  border: 0 !important;
 
}  */


.exchange-header {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.orders::-webkit-scrollbar {
  width: 6px;
  background-color: #161a1e;
}
.orders::-webkit-scrollbar-thumb {
  background-color: #5c5f7d;
}
.orders::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(56 53 53 / 30%);
  background-color: #2e2a4d;
}
.order-book-container,
.p2p-order-book__table {
  display: flex;
  position: relative;
}
/* .order-book-table--open,
.order-book-table--open thead tr th {
  width: 50%;
} */
.order-book-table {
  /* background-color: rgb(255, 255, 255); */
  margin-bottom: 0px;
  border-collapse: collapse;
  width: 100%;
  position: relative;
  /* table-layout: fixed; */
  border-spacing: 0px;
}
.order-book-table thead tr {
  /* height: 20px; */
  height: 0px !important;
  border: none;
  border-top: 0.1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.1);
}

.order-book-table thead tr th {
  border-bottom: 0.1px solid rgb(0, 0, 0, 0.1);
}
.order-book-table thead tr th > h6 {
  height: 22px;
  color: rgba(1, 8, 30, 0.6);
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;

  /* line-height: 1.2; */
  white-space: pre-line;
  vertical-align: middle;
  padding: 4px 14px;
}

.order-book-table tbody tr {
  height: 30px;
  cursor: pointer;
}
/* .order-book-table tbody.buy tr td {
  text-align: right;
} */
/* .order-book-table tbody tr td.status-dot-cell {
  text-align: center !important;
  padding: 0px;
} */
.order-book-table tbody tr td {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  /* letter-spacing: 0.4px; */
  max-width: 0px;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  padding: 2px 14px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  /* overflow: hidden; */
  font-weight: 500;
}
.order-book-table tbody tr .filler {
  position: absolute;
  height: 20px;
}
.order-book-table tbody.buy tr .filler {
  left: 0px;
  right: auto;
  background: rgba(36, 174, 143, 0.08);
}
.order-book-table--right {
  right: 0px;
}
.order-book-table tbody.sell tr .filler {
  left: 0px;
  right: auto;
  background: rgba(220, 86, 86, 0.08);
}

.exchange_styled {
  background-color: #f4f6f7;
  /* margin-top: 55px; */
  display: flex;
  font-size: 12px;
}

.left_layout {
  min-width: 730px;
  display: flex;
  flex-grow: 1;
  margin-right: 4px;
  flex-direction: column;
}

.right_layout {
  display: flex;
  flex-direction: column;
}

.exchange_styled .card {
  border: 1px solid rgb(188 188 188 / 18%) !important ;

}

.exchange_styled .card-header {
  background-color: #fff;
  border-bottom: 1px solid rgba(145, 148, 159, 0.2);
  color: #000 !important;
  font-size: 11.5px;
}

.exchange_input_grp .input-group-text {
  font-size: 12px;
  background-color: #F5F6F6 !important;
  color: #a6a6a6 !important;
  border: 1px solid #F5F6F6;
  /* border-radius: 0.175rem!important; */
}

.exchange_input_grp.input-group input {
  background-color: #F5F6F6;
  /* border-left: 0; */
  /* border-right: 0; */
  font-size: 12px;
  text-align: right;
  color: #000d1d;
  /* color: rgba(0, 13, 29, 0.6); */
  border-color: #F5F6F6;
}

.exchange_input_grp.input-group .form-control:focus {
  border-color: #eee;
  box-shadow: none !important;
}

.buy_sell_amount_picker {
  color: rgba(1, 8, 30, 0.6);
  background-color: rgba(1, 8, 30, 0.04);
  font-size: 12px;
  line-height: 1.8rem;
  height: 1.8rem;
  padding: 0 1rem;
  font-size: 0.8rem;
  width: calc(25% - 5px) !important;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
}

.exchange_tbl.table {
  font-size: 12px;
  margin-bottom: 0;
  /* table-layout: fixed; */
  border-spacing: 0px !important;

}

.exchange_tbl.table > tbody > tr > th,
.exchange_tbl.table > tbody > tr td {
  border-top: 1px solid transparent;
}

.exchange_tbl tr {
  height: 20px !important;
}

.table_striped tbody tr:nth-child(odd) {
  background-color: #ececec;
}

.table_on_hover .order-book-table tbody tr:hover {
  background-color: #f5f5f6 !important;
}

.btn-danger {
  background-color: #fa3f51 !important;
  border: 1px solid #fa3f51 !important;
}

.btn-success {
  background-color:rgb(1, 188, 141) !important;
  border-color: #49c774 !important;
}

.exchange_styled .footer_div {
  display: none;
}

/* for currency market hover */

.eth-market {
  background-color: #fff;
}

.hide-currency-market {
  display: none;
  z-index: 950;
  position: absolute;
  width: 30rem;
  top: 3rem;
  left: 1rem;
  background: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #ccc;
  /* box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important; */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2) !important;
}

/* .hide-currency-market-inner {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  color: #F5F5F7;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #272A2E;
} */

.currency_header:hover .hide-currency-market {
  display: block;
  bottom: 0;
}

.arrow_down {
  position: relative;
  top: -2px;
  border: solid #666;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  font-size: 9px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  width: 8px;
  height: 8px;
  margin-left: 15px;
}

.currency_header:hover .arrow_down {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  top: 4px;
  position: relative;
  border: solid #2196f3;
  border-width: 0 1px 1px 0;
}

.sidebar-overflow {
  height: 500px;
  /* min-height: 300px; */
  overflow-y: auto;
  overflow-x: hidden;
}

/* .exchange-pairs {
  border-radius: 3px;
  background-color: #272A2E;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  color: #F5F5F7;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
} */

.tooltip_box {
  box-shadow: 0 3px 4px 0 rgba(1, 8, 30, 0.16),
    0 1px 16px 0 rgba(1, 8, 30, 0.08);
  background-color: #ebebed;
  font-size: 14px;
  color: #01081e;
  padding: 16px 12px;
  width: 290px;
  word-wrap: break-word;
  font-weight: 400;
  z-index: 9999999;
  border-radius: 2px;
  /* text-align: center; */
  /* display: flex; */
  /* justify-content: space-between; */
}

.left_col {
  width: 140px;
}

.left_col .nav-pills .nav-link.active {
  color: white!important;
  background: black!important;
  border-bottom: 0!important;
}
.left_col .nav-pills .nav-link {
  font-size: 14px;
}
