.countdown-stake {
    margin-top: 3rem;
  }
  
  .banner-stake {
    display: flex;
    padding-left: 1rem;
    position: relative;
    height: 200px;
    margin-top: 3rem;
    background: #2fc19a;
    border-radius: 20px;
    overflow: hidden;
  }
  
  .banner-stake--container {
    /* background: url('../Assets/bg-7-min.png') center; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
  }
  
  .banner-stake--container.countdown-stake {
    /* background: url('../Assets/bg-7-min.png') center; */
    height: max-content;
    width: max-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    border: 6px solid #2fc19a;
    border-radius: 16px;
    width: 100%;
    max-width: 650px;
    margin: 0 20px;
  }
  
  .banner-stake__title {
    text-align: left;
    width: 40%;
  }
  
  .banner-stake__title h3 {
    color: #f09e40;
    font-size: 38px;
    font-weight: 600;
    margin: 0;
  }
  
  .banner-stake__desc {
    font-size: 22px;
    color: #fff;
  }
  
  .banner-stake__right {
    display: flex;
  }
  
  .banner-stake__total-lock, .banner-stake__price-wana {
    background: rgba(14, 14, 14, 0.7);
    border-radius: 20px;
    padding: 16px 24px;
    margin: 16px;
    color: #fff;
    min-width: 210px;
  }
  
  .banner-stake__total-lock .label, .banner-stake__price-wana .label {
    color: #ffff;
    font-size: 18px;
    font-weight: 500;
  }
  
  .banner-stake__total-lock .value, .banner-stake__price-wana .value {
    font-size: 26px;
    font-weight: 500;
    color: #fff200;
  }
  
  @media (max-width: 960px) {
    .banner-stake {
      height: unset;
    }
    .banner-stake--container {
      flex-direction: column;
    }
    .banner-stake__title {
      width: 100%;
      text-align: center;
    }
    .banner-stake__right {
      flex-wrap: wrap;
      white-space: nowrap;
      justify-content: center;
    }
    .bg-shadow {
      margin: auto;
    }
  }
  
  @media (max-width: 500px) {
    .banner-stake__right {
      margin-top: 1rem;
    }
    .banner-stake__title h3 {
      font-size: 28px;
    }
    .banner-stake {
      padding-left: 0;
    }
    .banner-stake__desc {
      font-size: 20px;
    }
    .banner-stake__total-lock, .banner-stake__price-wana {
      width: 100% !important;
      max-width: 263px;
      margin: 16px auto;
    }
    .bg-shadow {
      width: 100% !important;
      margin: 16px auto;
    }
  }
  @media (max-width: 350px) {
    .banner-stake__title h3 {
      font-size: 26px;
    }
    .banner-stake--container{
      padding: 20px 10px;
    }
  }
  
  .bg-shadow {
    width: max-content;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    z-index: 1;
  }
  
  .bg-shadow.dark {
    padding: 0
  }
  
  .bg-shadow.dark::before {
    padding: 0;
    display: none;
  }
  .bg-shadow::before {
    content: '';
    width: 100%;
    height: 100%;
    background: #2f2f2f85;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 20px;
  }
  
  .no-margin {
    margin: 0;
  }
  .staking-view {
    text-align: center;
  }
  
  .staking-view .list-staking {
    margin: 3rem 0;
  }
  
  .staking-view .list-staking .item-staking {
    align-self: baseline;
    background: rgb(255, 255, 255);
    border-radius: 32px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem 1.5rem;
    position: relative;
    text-align: center;
    border: 1px solid rgb(0 0 0 / 8%);
  }
  
  .staking-view .list-staking .item-staking .header-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
  }
  
  .staking-view .list-staking .item-staking .header-item .logo-token img {
    width: 64px;
    height: 64px;
  }
  
  .staking-view .list-staking .item-staking .header-item .info-pool .box-multi {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
  }
  .staking-view .list-staking .item-staking .header-item .info-pool .box-multi .text-core {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 16px;
    display: inline-flex;
    font-weight: 400;
    white-space: nowrap;
    height: 28px;
    padding: 0px 8px;
    font-size: 14px;
    background: transparent;
    border: 2px solid #2fc19a;
  }
  .multier-pool h3 {
    font-size: 15px !important;
    }
  .staking-view .list-staking .item-staking .header-item .info-pool .box-multi .text-core svg {
    fill: #2fc19a;
  }
  .staking-view .list-staking .item-staking .header-item .info-pool .box-multi .text-core span {
    margin-left: 0.3rem;
    color: #2fc19a;
  }
  .staking-view .list-staking .item-staking .header-item .info-pool .box-multi .multier-pool {
    margin: 0 0.25rem;
    padding: 0.05rem 0.6rem;
    border: 1px solid #1cc48c;
    border-radius: 1rem;
    background: #1cc48c;
  }
  .staking-view .list-staking .item-staking .header-item .info-pool .box-multi .multier-pool h3 {
    color: white;
    font-weight: bold;
    margin: 0;
  }
  
  .staking-view .list-staking .item-staking .header-item .info-pool h2 {
    margin: 0;
    font-weight: bold;
  }
  
  .staking-view .list-staking .item-staking .header-item .logo-token img {
    width: 64px;
    height: 64px;
  }
  
  .staking-view .list-staking .item-staking .content-item {
    margin: 1.5rem 0;
    border-bottom: 1px solid rgb(0 0 0 / 17%);
  }
  .staking-view .list-staking .item-staking .content-item .show-apr,
  .staking-view .list-staking .item-staking .content-item .show-earn,
  .staking-view .list-staking .item-staking .content-item .show-vesting,
  .staking-view .list-staking .item-staking .content-item .wrap-amount-stake {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
  }
  
  .staking-view .list-staking .item-staking .content-item .show-apr .apr {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
  }
  .staking-view .list-staking .item-staking .content-item .show-apr .apr span {
    margin: 0 0.25rem;
  }
  
  .staking-view .list-staking .item-staking .content-item .wrap-amount-stake {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .staking-view .list-staking .item-staking .content-item .wrap-amount-stake .token-earn {
    font-weight: bold;
  }
  
  .staking-view .list-staking .item-staking .content-item .wrap-amount-stake .token-earn .text-pink {
    font-weight: bold;
    color: #1cc48c !important;
  }
  .harvest .ant-btn {
    border-radius: 0.75rem;
    background: #2fc19a;
    border-color: #2fc19a;
    color: white;
    min-width: 82px;
  }
  
  .harvest .ant-btn[disabled],
  .harvest .ant-btn[disabled]:hover,
  .harvest .ant-btn[disabled]:focus,
  .harvest .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .staking-view .list-staking .item-staking .content-item .wrap-amount-stake .harvest button {
    border-radius: 0.75rem;
  }
  
  .staking-view .list-staking .item-staking .content-item .title-amount-stake,
  .staking-view .list-staking .item-staking .content-item .symbol-staked {
    text-align: start;
    font-weight: bold;
  }
  
  .symbol-staked {
    margin-top: 1rem;
  }
  
  .staking-view .list-staking .item-staking .content-item .btn-stake {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    border-radius: 1rem;
    background: #2fc19a;
    border: none;
    background-image: linear-gradient(132deg, #3fbb98, #e7c81e);
  }
  .ant-btn.ant-btn-round.ant-btn-lg.btn-confirm-farm {
    margin-top: 0.5rem;
    border-radius: 1rem;
    background: #2fc19a;
    border-color: #2fc19a;
    color: white;
  }
  
  .staking-view .list-staking .item-staking .content-item .show-earn {
    margin: 0.5rem 0;
  }
  
  .blur-text {
    color: rgb(0 0 0 / 50%);
  }
  
  .btn-show-detail {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    color: #2fc19a;
  }
  .btn-show-detail svg {
    fill: #2fc19a;
  }
  
  .staking-view .list-staking .item-staking .footer-item .detail-pool-stake {
    margin-top: 1rem;
  }
  
  .staking-view .list-staking .item-staking .footer-item .detail-pool-stake .total-liquidity {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
  }
  
  .staking-view
    .list-staking
    .item-staking
    .footer-item
    .detail-pool-stake
    .total-liquidity
    .amount-liquidity {
    font-size: 1rem;
  }
  
  .staking-view .list-staking .item-staking .footer-item .detail-pool-stake .redirect-swap,
  .staking-view .list-staking .item-staking .footer-item .detail-pool-stake .view-contract-pool,
  .staking-view .list-staking .item-staking .footer-item .detail-pool-stake .see-pair-info {
    text-align: start;
    margin-top: 0.25rem;
    font-weight: bold;
  }
  .staking-view .list-staking .item-staking .footer-item .detail-pool-stake a {
    color: #2fc19a;
  }
  .staking-view .list-staking .item-staking .footer-item .detail-pool-stake a svg {
    fill: #2fc19a;
  }
  
  .staking-view .list-staking .item-staking .content-item .wrap-show-vesting {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 1rem;
    background: #fafafa;
    padding: 0.5rem;
    margin-top: 0.75rem;
  }
  .staking-view .list-staking .item-staking .content-item .show-vesting .title-vesting {
    font-weight: bold;
    text-align: start;
    margin: 0.5rem 0;
  }
  .staking-view .list-staking .item-staking .content-item .title-amount-claimable {
    text-align: start;
    font-weight: bold;
    margin: 0.5rem 0;
    color: #2fc19a !important;
  }
  
  .multier-pool .wana-per-block {
    font-size: 0.9rem;
  }
  
  .token-vesting {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
  }
  
  .auto-restake-and-btn-detail {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
  }
  
  .auto-restake-and-btn-detail .btn-auto-restake {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 16px;
    display: inline-flex;
    font-weight: 400;
    white-space: nowrap;
    height: 28px;
    padding: 0px 8px;
    font-size: 14px;
    color: rgb(49, 208, 170);
    background: transparent;
    border: 2px solid rgb(49, 208, 170);
    cursor: default;
  }
  .auto-restake-and-btn-detail .btn-manual-restake {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 16px;
    display: inline-flex;
    font-weight: 400;
    white-space: nowrap;
    height: 28px;
    padding: 0px 8px;
    font-size: 14px;
    color: rgb(161 114 255);
    background: transparent;
    border: 2px solid rgb(158 110 255);
    cursor: default;
  }
  
  .auto-restake-and-btn-detail .ant-btn > .anticon {
    line-height: 0;
  }
  
  .btn-harvet {
    text-align: end;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
    flex: auto;
  }
  
  .btn-compound {
    margin-left: 0.5rem;
  }
  
  .staking-view .list-staking .item-staking .content-item .wrap-earn {
    margin: 0.5rem 0;
  }
  .staking-view .list-staking .item-staking .content-item .wrap-earn h3 {
    margin: 0;
  }
  
  .wrap-show-vesting .harvest {
    text-align: end;
  }
  
  @media (max-width: 768px) {
    .staking-view {
      padding-bottom: 2.5rem;
    }
  }
  
  .wrap-detail-and-harvest {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
  }
  






  .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon > * {
    line-height: 1;
  }

  .anticon svg {
    display: inline-block;
  }

  .anticon::before {
    display: none;
  }

  .anticon .anticon-icon {
    display: block;
  }

  .anticon[tabindex] {
    cursor: pointer;
  }

  .anticon-spin::before,
  .anticon-spin {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
    animation: loadingCircle 1s infinite linear;
  }

  @-webkit-keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  




  





  .input-stake-withdraw {
    width: 100%;
  }
  .input-stake-withdraw .input-stake-withdraw {
    width: 100%;
  }
  .input-stake-withdraw .actions-farm {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    width: 100%;
  }
  
  .actions-farm .action-stake,
  .actions-farm .action-withdraw {
    width: 48.9%;
  }
  
  .actions-farm .action-stake {
    padding-right: 0.15rem;
  }
  .actions-farm .action-withdraw {
    padding-left: 0.15rem;
  }
  
  .actions-farm .action-stake button,
  .actions-farm .action-withdraw button {
    width: 100%;
    border-radius: 0.75rem;
  }
  
  .input-stake-withdraw .actions-farm .action-stake .ant-btn {
    background: #2fc19a;
    border-color: #2fc19a;
    color: white;
  }
  
  .input-stake-withdraw .actions-farm .action-withdraw .ant-btn{
    background: #f09e40;
    border-color:#f09e40;
    color: white;
  }
  
  .input-stake-withdraw .input-amount {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    margin: 0.5rem 0;
  }
  .input-stake-withdraw .input-amount .ant-input-number {
    border-radius: 0.75rem;
  }
  
  .input-stake-withdraw .input-amount .ant-input-number-handler-wrap {
    display: none;
  }
  
  .input-stake-withdraw .input-amount .input-amount-stake,
  .input-stake-withdraw .input-amount .input-amount-unstake {
    width: 48.9%;
    border: 1px solid rgb(0 0 0 / 1%);
    border-radius: 0.75rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    background: rgb(190 190 190 / 25%);
  }
  
  .input-stake-withdraw .input-amount .input-amount-stake:hover,
  .input-stake-withdraw .input-amount .input-amount-unstake:hover {
    background: rgb(0 0 0 / 20%);
  }
  
  .input-stake-withdraw .input-amount .input-amount-stake {
    margin-right: 0.15rem;
  }
  .input-stake-withdraw .input-amount .input-amount-unstake {
    margin-left: 0.15rem;
  }
  
  .input-stake-withdraw .input-amount .input-amount-stake .ant-input-number,
  .input-stake-withdraw .input-amount .input-amount-unstake .ant-input-number {
    width: 78%;
    background: white;
    border-radius: 0.75rem;
  }
  .input-stake-withdraw .input-amount .input-amount-stake .btn-max,
  .input-stake-withdraw .input-amount .input-amount-unstake .btn-max {
    width: 24%;
    padding: 0.6rem 0.1rem;
    font-size: 0.7rem;
    height: 100%;
    height: 38px;
    cursor: pointer;
  }
  
  .staked-lp-amount,
  .balance-lp-amount {
    font-size: 1rem;
  }
  
  .balance-lp,
  .staked-lp {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
  }
  .ant-input-number-input{
    border: none;
    outline: none;
    background: transparent;
    padding: 9px 3px;
  }

  .modal-backdrop-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 1040;
  }